import { RxHttpMentoh } from "..";
import { wordPressConfig } from "../config";
// 课程相关接口
export class WordPressApi {
  private axios = new RxHttpMentoh(
    wordPressConfig.headers
  );

  /**获取文章列表*/
  public getWpV2Posts(params: { categories?: string | number, per_page: string | number, post?: number }) {
    return this.axios.Get<any, Array<GetWpPosts<{}>>>("/wp-json/wp/v2/posts", {
      params,
    });
  }

  /**获取学员规则列表*/
  public getRuleList(params: { per_page: string | number }) {
    return this.axios.Get<any, Array<GetWpPosts<{ rule_index: number }>>>("/wp-json/wp/v2/rule", {
      params,
    });
  }


  /**获取指定学员规则*/
  public getRule(id: number) {
    return this.axios.Get<any, Array<GetWpPosts<{ rule_index: number }>>>(`/wp-json/wp/v2/rule/${id}`);
  }


  /**获取介绍列表*/
  public getDescList(params?: { per_page: string | number }) {
    return this.axios.Get<any, Array<GetWpPosts<{ desc_type: string }>>>("/wp-json/wp/v2/desc", {
      params,
    });
  }


  /**获取指定介绍*/
  public getDesc(params: { desc_type: string }) {
    return this.axios.Get<any, Array<GetWpPosts<{}>>>(`/wp-json/wp/v2/desc`, { params });
  }


  /**获取指定文章 */
  public getWpV2Post(id: number) {
    return this.axios.Get<any, GetWpPosts<{}>>(`/wp-json/wp/v2/posts/${id}`)
  }

}
