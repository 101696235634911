import qs from "qs";
import { of } from "rxjs";
import { ajax, AjaxResponse } from "rxjs/ajax";
import { catchError, map, retry } from "rxjs/operators";
import { CourseApi } from "./apis/course";
import { WordPressApi } from "./apis/wordpress";

type RxResponseBody<T> = {
  data: T;
  msg: string;
  new_response: 1 | 0;
  status: 1 | 0;
};

export class RxRequest {
  public courseApi: CourseApi = new CourseApi();
  public wordPressApi: WordPressApi = new WordPressApi();
}

export class RxHttpMentoh {
  private headers: any = {};

  constructor(headers: any) {
    this.headers = headers;
  }

  public Get<P, T>(url: string, options?: { params: P | any; data?: P | any }) {
    if (options?.params) {
      Object.defineProperty(options?.params, 'time', { value: new Date().getTime(), enumerable: true })
    }
    // const urlSearchParams = new URLSearchParams()
    return ajax<RxResponseBody<T>>({
      url: `${url}`,
      queryParams: qs.stringify(options?.params, { arrayFormat: "brackets" }),
      method: "GET",
      headers: this.headers,
    }).pipe(
      retry<AjaxResponse<RxResponseBody<T>>>(5),
      // @ts-ignore
      map<AjaxResponse<RxResponseBody<T>>, T>((res) => {
        if (!res.response) {
          throw new Error("Value expected!");
        }
        const { data = res.response } = res.response;
        return data;
      }),
      catchError((err) => {
        console.error(err);
        return of();
      })
    );
  }

  public Post<P, D, T>(
    url: string,
    options: { data: D | any; params: P | any }
  ) {
    const { params, data } = options;
    return ajax<RxResponseBody<T>>({
      url: `${url}`,
      queryParams: qs.stringify(params, { arrayFormat: "brackets" }),
      body: data,
      method: "POST",
      headers: this.headers,
    }).pipe(
      retry<AjaxResponse<RxResponseBody<T>>>(5),
      map<AjaxResponse<RxResponseBody<T>>, T>((res: any) => {
        if (!res.response) {
          throw new Error("Value expected!");
        }
        const { data } = res.response;
        return data;
      }),
      catchError((err) => {
        console.error(err);
        return of();
      })
    );
  }
}
