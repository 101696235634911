import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { RxRequest } from "../plugins/request";
import { wordPressConfig } from "../plugins/request/config";
import htmr from "htmr";
import { Collapse, Image } from 'antd'

const { Panel } = Collapse
export class RuleService {
  private apis$: RxRequest
  constructor(rx_request: RxRequest) {
    this.apis$ = rx_request
  }

  // 获取学员规则列表
  public getRuleList$(): Observable<any> {
    return this.apis$
      .wordPressApi
      .getRuleList({
        per_page: 100
      })
      .pipe(
        map((res) => {
          const panels = res
            .sort((a, b) => {
              const { acf: { rule_index: a_index = 0} } = a
              const { acf: { rule_index: b_index = 0} } = b
              return +a_index - +b_index
            })
            .reduce((prev: Array<any>, { content: { rendered: text }, title: { rendered: title }, id }) => {
              const transform: any = {
                img: Image
              }
              const typography = htmr(text, { transform })
              prev.push((
                <Panel key={id} header={title}>
                  <div className="rx-post-blocks">
                    {typography}
                  </div>
                </Panel>
              ))
              return prev
            }, [])
          return panels
        })
      )
  }
}
