import './index.scss'

import { Collapse, Skeleton } from 'antd'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ServiceContext } from '../../plugins/context/manager'


const Rule: FC = () => {
    const [panel_dom, setPanelDOM] = useState<any>()
    const { rule_service } = useContext(ServiceContext)
    const [skeleton, setSkeleton] = useState(true)
    const { ruleId } = useParams()
    const [rule_active, setRuleActive] = useState(ruleId || 0)
    
    // 滚动定位
    useEffect(() => {
        const index = document.getElementsByClassName('ant-collapse-item-active').item(0)
        if (index === null) {
            return
        }
        setTimeout(() => {
            // @ts-ignore
            index.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        }, 300)

    }, [panel_dom])

    // 初始化
    useEffect(() => {
        rule_service?.getRuleList$()
            .subscribe((dom) => {
                setPanelDOM(dom)
                setSkeleton(false)
            })
    }, [])


    /** 折叠切换 */
    function collapseChange(key: any) {
        setRuleActive(key)
    }

    return (
        <>
            <Skeleton loading={skeleton} active paragraph={{ rows: 20 }} />
            <Collapse accordion activeKey={rule_active} onChange={collapseChange}>
                {panel_dom}
            </Collapse>

        </>
    );
}

export default Rule