import './index.scss'
import 'intro.js/introjs.css'
import { Steps, Hints } from 'intro.js-react'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { ServiceContext } from '../../../plugins/context/manager'
import { Image } from 'antd'
import { useWx } from '../../../hook/jssdk'

const IntroCourse: FC = () => {
    const { desc_service } = useContext(ServiceContext)
    // 显示步骤
    const [course_steps, setCourseSteps] = useState(false)
    const wx = useWx()
    const intro_options = {
        exitOnOverlayClick: false,
        nextLabel: '继续',
        prevLabel: '返回',
        skipLabel: '跳过',
        doneLabel: '关闭',
        tooltipClass: 'ck-intro-tooltip'
    }
    const steps = [
        {
            element: '.course-switch',
            intro: '课程切换按钮',
            position: 'right',
        },
        {
            element: '.course-filter',
            intro: '进入筛选界面',
            position: 'left',
        },
        {
            element: '.course-store',
            intro: '切换门店',
        },
        {
            element: '.course-room',
            intro: '切换教室',
        },
        {
            element: '.course-semester',
            intro: '学期信息',
        },
        {
            element: '.course-tags',
            intro: '课程状态',
        },
        {
            element: '.course-table',
            position: 'center',
            intro: '点击课表内的课程即可查看详情和进行购买',
        },
    ];
    useEffect(() => {
        document.title = '我要报名页面使用说明'
    }, [])
    // 图片加载完成
    function imageLoad() {
        setCourseSteps(true)
    }
    function stepExit() {
        wx.miniProgram.navigateBack(1)
    }
    return (
        <>
            <div className='intro-course-page'>
                <div className='intro-img'>
                    <Image
                        preview={false}
                        onLoad={imageLoad}
                        src='https://wp.balletme.com/wp-content/uploads/2022/05/intro_course_1-scaled.jpg'
                        height={'100vh'} />
                    <div className='course-switch'></div>
                    <div className='course-filter'></div>
                    <div className='course-store'></div>
                    <div className='course-room'></div>
                    <div className='course-semester'></div>
                    <div className='course-tags'></div>
                    <div className='course-table'></div>
                </div>
                <Steps
                    enabled={course_steps}
                    initialStep={0}
                    options={intro_options}
                    steps={steps}
                    onExit={stepExit}
                />
            </div>


        </>
    );
}

export default IntroCourse