import React, { FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.scss'
import Desc from './pages/desc'
import Home from './pages/home'
import IntroCourse from './pages/intro/course'
import Invoices from './pages/invoices'
import Rule from './pages/rule'
const App: FC = () => {
  return (
    <div className="rx-page">
      <BrowserRouter>
        <Routes >
          <Route path='/intro'>
            <Route path='course' element={<IntroCourse />} />
          </Route>
          <Route path='/home' element={<Home />} >
            <Route path='invoices' element={<Invoices />} />
            <Route index element={<Invoices />} />
          </Route>
          <Route path='/desc/type/:type' element={<Desc />}/>
          <Route path='/rule/index' element={<Rule />}>
            <Route path='id/:ruleId' element={<Rule />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>

  )
}

export default App
