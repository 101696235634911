import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { RxRequest } from "../plugins/request";
import { wordPressConfig } from "../plugins/request/config";
import htmr from "htmr";

export class DescService {
    private apis$: RxRequest
    constructor(rx_request: RxRequest) {
        this.apis$ = rx_request
    }

    // 获取介绍文本
    public getDesc$(payload: { desc_type: string }): Observable<{ content: any, title: string }> {
        return this.apis$
            .wordPressApi
            .getDescList()
            .pipe(
                map((res) => {
                    const filter_desc = res
                        .find(find => {
                            const { desc_type: pay_desc_type = '' } = payload
                            const { acf: { desc_type } } = find
                            if (pay_desc_type === '') {
                                return false
                            }
                            return new RegExp(`:${pay_desc_type}$`).test(desc_type)
                        })
                    return { content: filter_desc?.content.rendered || '', title: filter_desc?.title.rendered || '' }
                })
            )
    }

    // 福利详情
    public getWelfareDetail$(): Observable<{ desc: any, title: string }> {
        return this.apis$
            .wordPressApi
            .getWpV2Post(11)
            .pipe(
                map(({ title, content }) => {
                    const DESC_DOM = htmr(content.rendered)
                    return { desc: DESC_DOM, title: title.rendered }
                })
            )
    }
}
