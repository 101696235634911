import React from "react";
import { RuleService } from "../../service/rule";
import { DescService } from "../../service/desc";

type ServiceContextTypes = {
  rule_service?: RuleService;
  desc_service?: DescService;
};

export const ServiceContext = React.createContext<ServiceContextTypes>({});
