import './index.scss'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { ServiceContext } from '../../plugins/context/manager'
import { useParams } from 'react-router-dom'
import htmr from "htmr";

const Desc: FC = () => {
    const { desc_service } = useContext(ServiceContext)
    const [desc, setDesc] = useState(<></>)
    const { type = '0' } = useParams()
    useEffect(() => {
        desc_service
            ?.getDesc$({ desc_type: type })
            .subscribe(({ title, content }) => {
                const desc_transform: any = {
                    transform: { section: 'div' }
                }
                const DOM: any = htmr(content, desc_transform)
                setDesc(DOM)
                document.title = title  // 设置标题
            })
    }, [])
    return (
        <>
            <div style={{ overflowX: 'hidden' }}>
                {desc}
            </div>
        </>
    );
}

export default Desc