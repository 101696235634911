export const HttpConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const wordPressConfig = {
  headers: {
    "Content-Type": "application/json",
  }
};

export default HttpConfig;
