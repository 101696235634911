import { Outlet } from "react-router-dom";

export default function Home() {
    return (
        <div className="rx-home">
            未知
            <Outlet />
        </div>
    );
}
