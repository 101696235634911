import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import "antd/dist/antd.css"
import './index.less'
import reportWebVitals from './reportWebVitals'
import { ServiceContext } from './plugins/context/manager'
import { RxRequest } from './plugins/request'
import { RuleService } from './service/rule'
import { DescService } from './service/desc'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)


const apis$ = new RxRequest()

root.render(
  <ServiceContext.Provider value={{
    rule_service: new RuleService(apis$),
    desc_service: new DescService(apis$),
  }}>
    <App />
  </ServiceContext.Provider>
)

reportWebVitals()
